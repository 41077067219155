@import '~antd/dist/antd.css';
@import "../node_modules/react-date-range/dist/styles.css";
@import "../node_modules/react-date-range/dist/theme/default.css";

@font-face {
  font-family: 'Proxima Nova Black';
  src: url('/fonts/Proxima Nova Black.otf') format('truetype');
  font-style: normal;
  font-weight: 200;
}

@font-face {
  font-family: 'Proxima Nova Bold';
  src: url('/fonts/Proxima Nova Bold.otf') format('truetype');
  font-style: normal;
  font-weight: 200;
}

@font-face {
  font-family: 'Proxima Nova ExtraBold';
  src: url('/fonts/Proxima Nova ExtraBold.otf') format('truetype');
  font-style: normal;
  font-weight: 200;
}

@font-face {
  font-family: 'Proxima Nova Thin';
  src: url('/fonts/Proxima Nova Thin.otf') format('truetype');
  font-style: normal;
  font-weight: 200;
}

@font-face {
  font-family: 'Proxima Nova Regular';
  src: url('/fonts/Proxima Nova Regular.otf') format('truetype');
  font-style: normal;
  font-weight: 200;
}

@font-face {
  font-family: 'Lato-Black';
  src: url('/fonts/Lato-Black.ttf') format('truetype');
  font-style: normal;
  font-weight: 200;
}

@font-face {
  font-family: 'Lato-Bold';
  src: url('/fonts/Lato-Bold.ttf') format('truetype');
  font-style: normal;
  font-weight: 200;
}

@font-face {
  font-family: 'Lato-Regular';
  src: url('/fonts/Lato-Regular.ttf') format('truetype');
  font-style: normal;
  font-weight: 200;
}

@font-face {
  font-family: 'Lato-SemiBold';
  src: url('/fonts/Lato-SemiBold.ttf') format('truetype');
  font-style: normal;
  font-weight: 200;
}

body {
  color: #253053;
  font-family: Lato-Regular, sans-serif;
}

h1, h2, h3, h4, h5, h6 {
  color: #253053;
  font-family: "Proxima Nova Bold", sans-serif;
}

.container {
  width: 100%;
  margin: 0 auto;
  padding: 0 15px;
}

.MuiBackdrop-root {
  background-color: transparent !important;
}

@media (max-width: 767px) {
  .container {
    max-width: 100vw;
  }
}

@media (min-width: 768px) {
  .container {
    max-width: 730px;
  }
}

@media (min-width: 992px) {
  .container {
    max-width: 960px;
  }
}

@media (min-width: 1200px) {
  .container {
    max-width: 1170px;
  }
}

@media (min-width: 1440px) {
  .container {
    max-width: 1270px;
  }
}
